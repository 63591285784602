.App {
    text-align: center;
}

.App-logo, .ring, .xsring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.App-logo {
    top: -0%;
}

.ring {
    z-index: 2;
    top: 38%;
    left: 88%;
  }

.xsring {
    z-index: 3;
    top: 52%;
    left: 102%;
}

.image-container {
    position: relative;
    width: 200px; /* Set the width and height to the same value for a square */
    height: 200px; /* Adjust the height as needed */
  }



@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 22s linear;
    }
    .ring {
        animation: ring-spin infinite 45s linear;
    }
    .xsring {
        animation: xsring-spin infinite 50s linear;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes ring-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

@keyframes xsring-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: -1;
}
